import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import 'moment/locale/tr';

moment.locale('tr');

const permalink = (string) => {
	const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧıîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
	const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
	const p = new RegExp(a.split('').join('|'), 'g');

	let str = typeof string !== 'undefined' ? string : '';

	return (
		str
			.toString()
			.toLowerCase()
			.replace(/\s+/g, '-') // Replace spaces with -
			.replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
			.replace(/&/g, '-ve-') // Replace & with 've'
			// eslint-disable-next-line
			.replace(/[^\w\-]+/g, '') // Remove all non-word characters
			// eslint-disable-next-line
			.replace(/\-\-+/g, '-') // Replace multiple - with single -
			.replace(/^-+/, '') // Trim - from start of text
			.replace(/-+$/, '')
	); // Trim - from end of text
};

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

function News() {
	const [contents, setContents] = useState([]);

	const params = useParams();
	const query = useQuery();

	const id = params.id;

	const isDark = query.get('dark') ? Boolean(query.get('dark')) : false;

	function fetchNews() {
		axios.get(`https://io.ajansspor.com/tags/content/${id}?Limit=30`).then((res) => {
			if (res && res.data) {
				const data = res.data;
				setContents(data);
			}
		});
	}

	useEffect(() => {
		fetchNews();
		// eslint-disable-next-line
	}, []);

	return (
		<Wrapper dark={isDark}>
			<Helmet>
				<title>Transfer Haberleri</title>
			</Helmet>

			<Container>
				<Header>
					<Title dark={isDark}>Transfer Haberleri</Title>
				</Header>

				{contents.length > 0 &&
					contents.map((item, i) => (
						<Item
							key={i}
							href={`https://ajansspor.com/haber/${permalink(item.Title)}-${
								item.ContentId
							}?utm_source=transfer-haberleri&utm_medium=transfer-haberleri-widget&utm_campaign=widget&utm_id=transfer-haberleri-widget`}
							target="_blank"
						>
							<Image src={`https://ajssarimg2.mediatriple.net/${item.SpotMediaId}.jpg.webp?w=676&h=380`} alt={item.Title} />

							<NewsTitle>{item.Title}</NewsTitle>

							<Date>{moment(item.UpdatedDate).format('DD MMMM')}</Date>
						</Item>
					))}
			</Container>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	background-color: ${(props) => (props.dark ? '#000' : '#fff')};
	width: 100%;
	height: 100%;
	position: relative;
	padding: 20px;
	display: block;

	@media screen and (max-width: 480px) {
		padding: 10px;
	}
`;

const Container = styled.div`
	position: relative;
	z-index: 1;
`;

const Header = styled.header`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 20px;
`;

const Title = styled.h1`
	font-size: 16px;
	font-weight: 700;
	color: ${(props) => (props.dark ? '#fff' : '#000')};
	line-height: 24px;
`;

const Item = styled.a`
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: #eee;
	padding: 10px;
	border-radius: 6px;
	margin-bottom: 16px;
`;

const Image = styled.img`
	width: 96px;
	height: 54px;
	object-fit: cover;
	border-radius: 8px;
	margin-right: 10px;

	@media screen and (max-width: 480px) {
		width: 80px;
		height: 45px;
	}
`;

const NewsTitle = styled.p`
	font-size: 11px;
	font-weight: bold;
	color: #050505;
	line-height: 16px;
	max-width: 50%;
`;

const Date = styled.span`
	font-size: 10px;
	font-weight: bold;
	color: #333;
	margin-left: auto;
`;

export default News;
