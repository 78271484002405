import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Match from './widgets/match';
import PointStatus from './widgets/point-status';
import News from './widgets/news';

const App = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/match/:id" element={<Match />} />
				<Route path="/point-status/:league/:season" element={<PointStatus />} />
				<Route path="/news/:id" element={<News />} />
			</Routes>
		</BrowserRouter>
	);
};

export default App;
