import LocaleTeam from './team.json';
import LocaleLeague from './league.json';
import LocaleCountry from './country.json';

const LocaleTeamName = (str) => {
	const get = LocaleTeam.find((x) => x.en === str);

	if (get) {
		if (get.tr) return get.tr;
		else return get.en;
	} else {
		return str;
	}
};

const LocaleTeamShort = (str) => {
	const get = LocaleTeam.find((x) => x.en === str);

	if (get) {
		if (get.code) return get.code;
		else return str;
	} else {
		return str;
	}
};

const LocaleTeamCat = (str) => {
	const get = LocaleTeam.find((x) => x.en === str);

	if (get) {
		if (get.tag) return get.tag;
		else return '16';
	} else {
		return '16';
	}
};

const LocaleLeagueName = (str) => {
	const get = LocaleLeague.find((x) => x.en === str);

	if (get) {
		if (get.tr) return get.tr;
		else return get.en;
	} else {
		return str;
	}
};

const LocaleLeagueCat = (str) => {
	const get = LocaleLeague.find((x) => x.en === str);

	if (get) {
		if (get.tag) return get.tag;
		else return '16';
	} else {
		return '16';
	}
};

const LocaleCountryName = (str) => {
	const get = LocaleCountry.find((x) => x.en === str);

	if (get) {
		if (get.tr) return get.tr;
		else return get.en;
	} else {
		return str;
	}
};

export { LocaleTeamName, LocaleTeamShort, LocaleTeamCat, LocaleLeagueName, LocaleLeagueCat, LocaleCountryName };
