import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { LocaleLeagueName, LocaleTeamName } from '../utils';

const MediaPathReplacer = (string) => {
	if (string.toString().indexOf('/teams/611.png') > -1) {
		return 'https://ajansspor.com/assets/images/icons/fenerbahce.svg';
	}
	return string;
};

const permalink = (string) => {
	const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧıîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
	const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
	const p = new RegExp(a.split('').join('|'), 'g');

	let str = typeof string !== 'undefined' ? string : '';

	return (
		str
			.toString()
			.toLowerCase()
			.replace(/\s+/g, '-') // Replace spaces with -
			.replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
			.replace(/&/g, '-ve-') // Replace & with 've'
			// eslint-disable-next-line
			.replace(/[^\w\-]+/g, '') // Remove all non-word characters
			// eslint-disable-next-line
			.replace(/\-\-+/g, '-') // Replace multiple - with single -
			.replace(/^-+/, '') // Trim - from start of text
			.replace(/-+$/, '')
	); // Trim - from end of text
};

const matchStatus = (t) => {
	if (t === 'TBD' || t === 'NS') return { status: 'Başlamadı', live: false };
	if (t === '1H') return { status: 'İlk Yarı', live: true };
	if (t === 'HT') return { status: 'Devre Arası', live: true };
	if (t === '2H') return { status: '2. Yarı', live: true };
	if (t === 'ET') return { status: 'Uzatmalar', live: true };
	if (t === 'P') return { status: 'Penaltılar', live: true };
	if (t === 'FT') return { status: 'Bitti', live: false };
	if (t === 'AET') return { status: 'Uzatmalarla Bitti', live: false };
	if (t === 'PEN') return { status: 'Penaltılarla Bitti', live: false };
	if (t === 'BT') return { status: 'Kayıp Zaman', live: false };
	if (t === 'SUSP') return { status: 'Durduruldu', live: false };
	if (t === 'INT') return { status: 'Durduruldu', live: false };
	if (t === 'PST') return { status: 'Ertelendi', live: false };
	if (t === 'CANC') return { status: 'İptal Edildi', live: false };
	if (t === 'ABD') return { status: 'İptal Edildi', live: false };
	if (t === 'AWD') return { status: 'Durduruldu', live: false };
	if (t === 'WO') return { status: 'Durduruldu', live: false };
	if (t === 'LIVE') return { status: 'Başladı', live: true };
};

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Match = () => {
	const [status, setStatus] = useState('ended');
	const [time, setTime] = useState(0);
	const [stoppage, setStoppage] = useState(0);

	const [homeTeamId, setHomeTeamId] = useState(null);
	const [awayTeamId, setAwayTeamId] = useState(null);

	const [homeTeamLogo, setHomeTeamLogo] = useState('');
	const [awayTeamLogo, setAwayTeamLogo] = useState('');

	const [homeTeamName, setHomeTeamName] = useState('-');
	const [awayTeamName, setAwayTeamName] = useState('-');

	const [homeTeamScoreHalf, setHomeTeamScoreHalf] = useState(0);
	const [awayTeamScoreHalf, setAwayTeamScoreHalf] = useState(0);

	const [homeTeamScore, setHomeTeamScore] = useState(0);
	const [awayTeamScore, setAwayTeamScore] = useState(0);

	const [leagueName, setLeagueName] = useState('');
	const [leagueId, setLeagueId] = useState('');

	const [homeTeamScoreDiff, setHomeTeamScoreDiff] = useState('down');
	const [awayTeamScoreDiff, setAwayTeamScoreDiff] = useState('down');

	const [isLive, setIsLive] = useState(false);
	const [isEnded, setIsEnded] = useState(false);

	const [events, setEvents] = useState([]);

	const [homeGoals, setHomeGoals] = useState([]);
	const [awayGoals, setAwayGoals] = useState([]);

	const [homeYellowCards, setHomeYellowCards] = useState([]);
	const [awayYellowCards, setAwayYellowCards] = useState([]);

	const [homeRedCards, setHomeRedCards] = useState([]);
	const [awayRedCards, setAwayRedCards] = useState([]);

	const params = useParams();

	const query = useQuery();

	const fetchMatch = () => {
		axios.get(`https://ajsapicache.mediatriple.net/soccer/fixture/${params.id}`).then((res) => {
			const data = res.data.data[0];

			setStatus(matchStatus(data?.fixture?.status?.short).status);
			setTime(data?.fixture?.status?.elapsed);
			setStoppage(0);

			setHomeTeamId(data?.teams?.home?.id);
			setAwayTeamId(data?.teams?.away?.id);

			setHomeTeamLogo(data?.teams?.home?.logo);
			setAwayTeamLogo(data?.teams?.away?.logo);

			setHomeTeamName(data?.teams?.home?.name);
			setAwayTeamName(data?.teams?.away?.name);

			setHomeTeamScoreHalf(data?.score?.halftime?.home);
			setAwayTeamScoreHalf(data?.score?.halftime?.away);

			setHomeTeamScore(data?.goals?.home);
			setAwayTeamScore(data?.goals?.away);

			setLeagueName(data?.league?.name);
			setLeagueId(data?.league?.logo);

			setHomeTeamScoreDiff(data?.goals?.home < data?.goals?.away ? 'down' : 'up');
			setAwayTeamScoreDiff(data?.goals?.away < data?.goals?.home ? 'down' : 'up');

			setEvents(data?.event);

			setIsLive(matchStatus(data?.fixture?.status?.short).live);

			if (matchStatus(data?.fixture?.status?.short).status === 'Bitti') {
				setIsEnded(true);
			}
		});
	};

	useEffect(() => {
		if (params.id) {
			fetchMatch(params.id);

			setInterval(() => fetchMatch(params.id), 15000);
		}
		// eslint-disable-next-line
	}, [params.id]);

	useEffect(() => {
		if (events && events.length > 0) {
			const homeTeamEvents = events.filter((e) => e.team.id === homeTeamId);
			const awayTeamEvents = events.filter((e) => e.team.id === awayTeamId);

			const homeTeamGoals = homeTeamEvents.filter((e) => e.type === 'Goal');
			const awayTeamGoals = awayTeamEvents.filter((e) => e.type === 'Goal');

			const homeTeamYellowCards = homeTeamEvents.filter((e) => e.detail === 'Yellow Card');
			const awayTeamYellowCards = awayTeamEvents.filter((e) => e.detail === 'Yellow Card');

			const homeTeamRedCards = homeTeamEvents.filter((e) => e.detail === 'Red Card');
			const awayTeamRedCards = awayTeamEvents.filter((e) => e.detail === 'Red Card');

			setHomeGoals(homeTeamGoals);
			setAwayGoals(awayTeamGoals);
			setHomeYellowCards(homeTeamYellowCards);
			setAwayYellowCards(awayTeamYellowCards);
			setHomeRedCards(homeTeamRedCards);
			setAwayRedCards(awayTeamRedCards);
		}
		// eslint-disable-next-line
	}, [events]);

	const teamGoals = (goals) => {
		return (
			<div className="flex flex-col items-center mt-8">
				<img src="/goal.png" alt="Gol" className="w-6 h-6 mb-2 hidden dark:block" />
				<img src="/goal-dark.png" alt="Gol" className="w-6 h-6 mb-2 block dark:hidden" />

				{goals.map((goal, i) => (
					<div key={i} className="mt-1 flex flex-row items-center">
						<span className="block font-sans font-semibold text-pink text-xs md:text-base">'{goal?.time?.elapsed}</span>
						<span className="block font-sans font-semibold text-black dark:text-white ml-2 text-xs md:text-base">{goal.player?.name}</span>
					</div>
				))}
			</div>
		);
	};

	const teamCards = (yellow, red) => {
		return (
			<>
				{query.get('yellowCards') === 'yes' && yellow.length > 0 && (
					<div className="flex flex-col items-center">
						<img src="/yellow-card.svg" alt="" className="w-4 h-4 mb-2" />

						{yellow.map((card, i) => (
							<div key={i} className="mt-1 flex flex-row items-center">
								<span className="block font-sans font-semibold text-pink text-xs md:text-base">'{card?.time?.elapsed}</span>
								<span className="block font-sans font-semibold text-black dark:text-white ml-2 text-xs md:text-base">{card?.player?.name}</span>
							</div>
						))}
					</div>
				)}

				{query.get('redCards') === 'yes' && red.length > 0 && (
					<div className="flex flex-col items-center mt-6">
						<img src="/red-card.svg" alt="" className="w-4 h-4 mb-2" />

						{red.map((card, i) => (
							<div key={i} className="mt-1 flex flex-row items-center">
								<span className="block font-sans font-semibold text-pink text-xs md:text-base">'{card?.time?.elapse}</span>
								<span className="block font-sans font-semibold text-black dark:text-white ml-2 text-xs md:text-base">{card?.player?.name}</span>
							</div>
						))}
					</div>
				)}
			</>
		);
	};

	return (
		<a
			href={`https://ajansspor.com/futbol/mac-ozeti/${permalink(LocaleTeamName(homeTeamName))}-${permalink(LocaleTeamName(awayTeamName))}/${
				params.id
			}?utm_source=haber-detay&utm_medium=haber-widget&utm_campaign=widget&utm_id=mac-widget`}
			target="_blank"
			rel="noreferrer"
			className="flex flex-col bg-grey dark:bg-black m-0 p-4 rounded-10xl"
		>
			<div className="my-2 w-full text-center md:my-4">
				<img src="/logo.svg" alt="Ajansspor" className="m-auto h-4 md:h-6" />
			</div>

			<div className="w-full md:px-10 relative">
				<div className="absolute left-1/2 top-1/2 h-full w-full -translate-x-2/4 -translate-y-2/4">
					<img src="/logo-white.svg" alt="Ajansspor" className="opacity-5 mx-auto h-24 hidden dark:block" />
					<img src="/logo-dark.svg" alt="Ajansspor" className="opacity-5 mx-auto h-24 block dark:hidden" />
				</div>

				<div className="flex flex-col relative z-10">
					<div className="flex flex-row md:flex-col text-center justify-center items-center">
						{isLive && !isEnded ? (
							<span className="block font-sans font-medium text-black dark:text-white">
								{time}
								{stoppage > 0 ? ' + ' + stoppage : ''}'
							</span>
						) : (
							<span className="block font-sans font-medium text-black dark:text-white">{status}</span>
						)}
						{time > 0 && <span className="block font-sans-font-medium text-black dark:text-white text-xs opacity-50 ml-2 md:ml-0">({status})</span>}
					</div>

					<div className="flex flex-row items-center justify-center py-1 mt-3 md:mt-6">
						{leagueId && (
							<div className="rounded-sm bg-light p-2">
								<img src={MediaPathReplacer(leagueId)} alt="" className="h-6" />
							</div>
						)}
						<p className="font-sans font-medium text-black dark:text-white text-center text-sm ml-2">{LocaleLeagueName(leagueName)}</p>
					</div>

					<div className="flex flex-row my-4">
						<div className="w-1/3">
							<div className="flex flex-col items-center">
								{homeTeamId && <img src={MediaPathReplacer(homeTeamLogo)} alt={LocaleTeamName(homeTeamName)} width="72" height="72" className="w-6 h-6 md:w-9 md:h-9" />}
								<span className="block font-sans font-medium text-sm md:text-lg text-black dark:text-white mt-1 md:mt-3">{LocaleTeamName(homeTeamName)}</span>
							</div>
						</div>

						<div className="flex flex-col items-center w-1/3">
							<div className="flex flex-row items-center justify-center">
								<span
									className={`block font-sans font-semibold text-lg ${isLive ? 'md:text-2xl' : 'md:text-4xl'} text-black dark:text-white ${
										homeTeamScoreDiff === 'down' ? 'opacity-50' : ''
									}`}
								>
									{homeTeamScore}
								</span>

								{isLive ? (
									<div className="flex flex-row items-center bg-black dark:bg-white bg-opacity-10 rounded-xl p-2 md:p-2 mx-4 md:mx-6">
										<div className="w-3 h-3 md:w-4 md:h-4 hidden md:flex items-center justify-center border-2 border-pink rounded-lg relative">
											<div className="w-1 h-1 md:w-2 md:h-2 rounded-lg bg-pink"></div>
										</div>
										<span className="font-sans font-semibold text-xs md:text-base text-pink md:ml-2">Canlı</span>
									</div>
								) : (
									<span className="text-black dark:text-white mx-2"> - </span>
								)}

								<span
									className={`block font-sans font-semibold text-lg ${isLive ? 'md:text-2xl' : 'md:text-4xl'} text-black dark:text-white ${
										awayTeamScoreDiff === 'down' ? 'opacity-50' : ''
									}`}
								>
									{awayTeamScore}
								</span>
							</div>

							{(status === '2. Yarı' || status === 'Tamamlandı') && (
								<span className="block text-black dark:text-white mt-2 opacity-50">
									{homeTeamScoreHalf} - {awayTeamScoreHalf}
								</span>
							)}
						</div>

						<div className="w-1/3">
							<div className="flex flex-col items-center">
								{awayTeamId && <img src={MediaPathReplacer(awayTeamLogo)} alt={LocaleTeamName(awayTeamName)} width="72" height="72" className="w-6 h-6 md:w-9 md:h-9" />}
								<span className="block font-sans font-medium text-sm md:text-lg text-black dark:text-white mt-1 md:mt-3">{LocaleTeamName(awayTeamName)}</span>
							</div>
						</div>
					</div>

					<div className="flex flex-row my-4 px-6 justify-between">
						{query.get('goals') === 'yes' && homeGoals.length > 0 && teamGoals(homeGoals)}
						{query.get('goals') === 'yes' && awayGoals.length > 0 && teamGoals(awayGoals)}
					</div>
				</div>
			</div>

			<div className="w-full bg-black dark:bg-white bg-opacity-5 dark:bg-opacity-10 rounded-md py-1">
				<p className="font-sans font-medium text-sm text-black dark:text-white opacity-40 text-center">Veriler sürekli güncellenmektedir.</p>
			</div>

			{((query.get('yellowCards') === 'yes' && (homeYellowCards.length > 0 || awayYellowCards.length > 0)) ||
				(query.get('redCards') === 'yes' && (homeRedCards.length > 0 || awayRedCards.length > 0))) && (
				<div className="w-full flex flex-row justify-between bg-black dark:bg-white bg-opacity-5 dark:bg-opacity-10 rounded-md py-4 mt-2 px-6 md:px-10">
					<div className="flex flex-col">{teamCards(homeYellowCards, homeRedCards)}</div>
					<div className="flex flex-col">{teamCards(awayYellowCards, awayRedCards)}</div>
				</div>
			)}
		</a>
	);
};

export default Match;
