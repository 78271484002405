import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { LocaleTeamName } from '../utils';

moment.locale('tr');

const MediaPathReplacer = (string) => {
	if (string.toString().indexOf('/teams/611.png') > -1) {
		return 'https://ajansspor.com/assets/images/icons/fenerbahce.svg';
	}
	return string;
};

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PointStatus = () => {
	const params = useParams();
	const query = useQuery();

	const [standings, setStandings] = useState([]);
	const isDark = query.get('dark') ? Boolean(query.get('dark')) : false;

	function fetchPointStatus() {
		axios.get(`https://ajsapicache.mediatriple.net/soccer/standing/${params.league}/${params.season || moment().format('YYYY')}`).then((res) => {
			if (res && res.data) {
				const data = res.data.data[0];

				if (data.standings && data.standings.length > 0) {
					if (query.get('count')) {
						setStandings(data.standings[0].slice(0, parseInt(query.get('count'), 10)));
					} else {
						setStandings(data.standings[0]);
					}
				}
			}
		});
	}

	useEffect(() => {
		fetchPointStatus();
		// eslint-disable-next-line
	}, []);

	return (
		<Wrapper
			dark={isDark}
			href={`https://ajansspor.com/futbol/lig/puan-durumu/${params.league}?utm_source=puan-durumu&utm_medium=puan-durumu-widget&utm_campaign=widget&utm_id=puan-durumu-widget`}
			target="_blank"
		>
			<Helmet>
				<title>Puan Durumu</title>
			</Helmet>

			<Container>
				<Header>
					<Title dark={isDark}>Puan Durumu</Title>
				</Header>

				{standings.length > 0 ? (
					<Overflow>
						<Table>
							<Thead>
								<tr>
									<th scope="col" className="text-left w-9">
										<ThSpan dark={isDark} secondary>
											#
										</ThSpan>
									</th>
									<th scope="col" colSpan={5} className="text-left">
										<ThSpan dark={isDark}>Takım</ThSpan>
									</th>
									<th scope="col">
										<ThSpan dark={isDark}>O</ThSpan>
									</th>
									<th scope="col">
										<ThSpan dark={isDark}>A./Y. Gol</ThSpan>
									</th>
									<th scope="col">
										<ThSpan dark={isDark}>Av</ThSpan>
									</th>
									<th scope="col">
										<ThSpan dark={isDark}>P</ThSpan>
									</th>
								</tr>
							</Thead>

							<tbody>
								{standings.map((std, i) => (
									<tr key={i}>
										<TD>
											<Background radius="10px 0 0 10px" dark={isDark}>
												<Rank dark={isDark}>{std.rank}</Rank>
											</Background>
										</TD>
										<TD colSpan={5}>
											<Background dark={isDark}>
												<Team>
													<TeamLogo src={MediaPathReplacer(std.team.logo)} alt={LocaleTeamName(std.team.name)} />
													<TeamName dark={isDark}>{LocaleTeamName(std.team.name)}</TeamName>
												</Team>
											</Background>
										</TD>
										<TD className="text-center">
											<Background dark={isDark}>
												<TdSpan dark={isDark}>{std.all.draw + std.all.win + std.all.lose}</TdSpan>
											</Background>
										</TD>
										<TD className="text-center">
											<Background dark={isDark}>
												<TdSpan dark={isDark}>
													{std.all.goals.for} / {std.all.goals.against}
												</TdSpan>
											</Background>
										</TD>
										<TD className="text-center">
											<Background dark={isDark}>
												<TdSpan dark={isDark}>{std.goalsDiff}</TdSpan>
											</Background>
										</TD>
										<TD className="text-center">
											<Background radius="0 10px 10px 0" dark={isDark}>
												<TdSpan dark={isDark}>{std.points}</TdSpan>
											</Background>
										</TD>
									</tr>
								))}
							</tbody>
						</Table>
					</Overflow>
				) : (
					<NoMatchData>Puan durumu yükleniyor...</NoMatchData>
				)}
			</Container>

			{/* {standings.length > 0 && <Mask />} */}
		</Wrapper>
	);
};

const Wrapper = styled.a`
	background-color: ${(props) => (props.dark ? '#000' : '#fff')};
	width: 100%;
	height: 100%;
	position: relative;
	padding: 30px 20px;
	display: block;
`;

const Container = styled.div`
	position: relative;
	z-index: 1;
`;

const Header = styled.header`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const Title = styled.h1`
	font-size: 16px;
	font-weight: 700;
	color: ${(props) => (props.dark ? '#fff' : '#000')};
	line-height: 24px;
`;

// const LogoContainer = styled.a`
// 	display: block;
// 	margin-left: auto;
// `;

// const Logo = styled.img`
// 	height: 18px;
// `;

const Table = styled.table`
	width: 100%;
	margin-top: 20px;
`;

const Thead = styled.thead`
	border-bottom: 8px solid transparent;
`;

const ThSpan = styled.span`
	font-size: 12px;
	font-weight: 500;
	color: ${(props) => (props.dark ? 'rgba(255,255,255,.5)' : 'rgba(0, 0, 0, 0.5)')};
	padding-left: ${(props) => (props.secondary ? 12 : 0)}px;
`;

const TD = styled.td`
	padding: 4px 0px;
	vertical-align: middle;
`;

const Rank = styled.div`
	width: 28px;
	height: 28px;
	border-radius: 10px;
	background: ${(props) => (props.dark ? '#000' : '#fff')};

	font-size: 10px;
	font-weight: 700;
	color: ${(props) => (props.dark ? 'rgba(255,255,255,.5)' : 'rgba(0, 0, 0, 0.5)')};
	text-align: center;
	line-height: 28px;

	margin-right: auto;
`;

const TdSpan = styled.span`
	font-size: 12px;
	font-weight: 500;
	color: ${(props) => (props.dark ? 'rgba(255,255,255,.5)' : 'rgba(0, 0, 0, 0.5)')};
`;

const Team = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 100%;
`;

const TeamLogo = styled.img`
	width: 16px;
	height: 16px;
	object-fit: contain;
	margin-right: 6px;
`;

const TeamName = styled.span`
	font-size: 12px;
	font-weight: 700;
	color: ${(props) => (props.dark ? '#fff' : '#000')};
	line-height: 18px;
`;

const Background = styled.div`
	height: 32px;
	line-height: 26px;
	background: ${(props) => (props.dark ? '#212121' : '#fafafa')};
	border-radius: ${(props) => (props.radius ? props.radius : 0)};
	padding: 2px;
`;

const NoMatchData = styled.div`
	font-size: 24px;
	font-weight: 500;
	color: black;
	text-align: center;
	margin: 30px 0;
`;

const Overflow = styled.div``;

// const Mask = styled.div`
// 	height: 105%;
// 	width: 300px;
// 	background: #000;
// 	position: absolute;
// 	left: -150px;
// 	top: -46px;
// 	bottom: 0;
// 	transform: rotate(14deg);
// 	border-radius: 20px;
// `;

export default PointStatus;
